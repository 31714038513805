'use client';

// We can not useState or useRef in a server component, which is why we are
// extracting this part out into its own file with 'use client' on top
import { useInitModal } from '@komo-tech/ui/Modal/init';
import {
  OptionalThemeProvider,
  ThemeProviderProps
} from '@komo-tech/ui/theme/Provider';
import { OptionalToasterProvider } from '@komo-tech/ui/Toast/Provider';
import { ToastPosition } from '@komo-tech/ui/Toast/ToastPosition';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { KomoPostHogProvider } from '@/admin/components/posthog/KomoPostHogProvider';

import { init } from '../../init';
import UserflowRemount from './UserflowRemount';

init();

export interface AdminProvidersProps
  extends Pick<ThemeProviderProps, 'forceColorScheme'> {
  children: React.ReactNode;
  withThemeProvider?: boolean;
}

// Why is PostHogPageView dynamically imported? It contains the useSearchParams hook, which deopts the entire app into client-side rendering if it is not dynamically imported.
const PostHogPageView = dynamic(() => import('./PostHogPageView'), {
  ssr: false
});

export const AdminProviders = ({
  children,
  forceColorScheme,
  withThemeProvider = true
}: AdminProvidersProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 60 * 1000,
            onError: (error) => console.error(error)
          },
          mutations: {
            onError: (error) => console.error(error)
          }
        }
      })
  );

  useInitModal();

  return (
    <KomoPostHogProvider>
      <QueryClientProvider client={queryClient}>
        <OptionalThemeProvider
          withTheme={withThemeProvider}
          forceColorScheme={forceColorScheme}
        >
          <OptionalToasterProvider
            hide={!withThemeProvider}
            position={ToastPosition.BottomLeft}
          >
            <PostHogPageView />
            <UserflowRemount />
            {children}
          </OptionalToasterProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </OptionalThemeProvider>
      </QueryClientProvider>
    </KomoPostHogProvider>
  );
};
