import { useMemo } from 'react';

import { resolveSubscriptionEntitlements } from '@/admin/components/shared/Companies/Billing/helper';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';
import { AdminBillingSubscriptionUsage } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscriptionUsage';
import { BillingSubscriptionEntitlement } from '@/common/models/billing/subscriptions/shared/BillingSubscriptionEntitlement';

export const useSubscriptionEntitlements = (
  subscription?: AdminBillingSubscription,
  usage?: AdminBillingSubscriptionUsage
): BillingSubscriptionEntitlement[] => {
  return useMemo(
    () => resolveSubscriptionEntitlements(subscription, usage),
    [subscription, usage]
  );
};
