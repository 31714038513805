import { useCompanyActiveSubscription } from '@/admin/components/shared/Companies/_useCompanyActiveSubscription';
import { useSubscriptionEntitlements } from '@/admin/components/shared/Companies/Billing/useSubscriptionEntitlements';
import { AdminBillingSubscriptionService } from '@/admin/data/Billing/Subscriptions';
import { Company } from '@/common/models/companies/Company';

export const useActiveSubscriptionUsage = (company?: Company) => {
  const { data: activeSubscription, isLoading: subscriptionLoading } =
    useCompanyActiveSubscription(company?.id, { enabled: !!company });
  const { data: usage, isLoading: usageLoading } =
    AdminBillingSubscriptionService.hooks.useGetUsage(
      company?.activeSubscription?.id,
      { enabled: !!company?.activeSubscription }
    );

  const entitlements = useSubscriptionEntitlements(activeSubscription, usage);

  return {
    activeSubscription,
    subscriptionLoading,
    usage,
    usageLoading,
    entitlements
  };
};
