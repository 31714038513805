'use client';
import { FCC } from 'fcc';
import posthog, { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { isProduction } from '@/common/utils/NextFunctions';

const apiKey =
  process.env.NEXT_PUBLIC_KOMO_ENVIRONMENT === 'production'
    ? 'phc_TbVpmTwsph5l5JsX11qrNtJ0tZuV0Am54NuBx6J4CVQ' // Production
    : 'phc_cEjNCs9BDEFDflQujTr2SABOFU1gpuHLNQkpBwA0a2q'; // Staging

const options: Partial<PostHogConfig> = {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'identified_only',
  capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  loaded: (posthog) => {
    if (!isProduction()) posthog.debug(false); // Set to true to enable debug mode in development
  }
};

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined' && isProduction()) {
  posthog.init(apiKey, options);
}

export const KomoPostHogProvider: FCC = ({ children }) => {
  if (!isProduction()) return children;

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
