'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { UserflowService } from '@/admin/utils/UserflowService';

/**
 * Remounts Userflow on pathname change.
 * Kind of a hack to get userflow widget to show on app router page change.
 */
export default function UserflowRemount() {
  const pathname = usePathname();

  useEffect(() => {
    UserflowService.remount();
  }, [pathname]);

  return null;
}
