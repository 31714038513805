import { Guid } from '@komo-tech/core/models/Guid';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AdminCompanyService } from '@/admin/data/AdminCompanyService';
import { AdminBillingSubscription } from '@/common/models/billing/subscriptions/Admin/AdminBillingSubscription';

export const useCompanyActiveSubscription = (
  companyId: Guid | undefined,
  options: Partial<UseQueryOptions<AdminBillingSubscription>> = {}
) =>
  useQuery(
    AdminCompanyService.cacheKeys.activeSubscription(companyId),
    () => AdminCompanyService.getActiveSubscriptionAsync(companyId),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
