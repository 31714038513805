'use client';
import { useMutation } from '@tanstack/react-query';
import addMinutes from 'date-fns/addMinutes';
import isBefore from 'date-fns/isBefore';
import { useEffect } from 'react';

import { useWindowFocused } from '@/common/utils/useWindowFocused';

import { AdminAuthService } from '../data/AdminAuth/AdminAuthService';

let lastCheck = new Date();

export const useCheckAdministratorSession = (
  logoutCallback: (url: string) => void
) => {
  const [handleLogout] = AdminAuthService.hooks.useAdminLogout({
    onRouterPush: logoutCallback
  });
  const { mutateAsync: checkValidSession } = useMutation(
    () => {
      return AdminAuthService.actions.checkValidSessionAsync();
    },
    {
      onSuccess: (data) => {
        if (!data.success) {
          handleLogout();
        }
      },
      onError: () => {
        handleLogout();
      }
    }
  );

  const focus = useWindowFocused();

  useEffect(() => {
    if (!focus) return () => {};
    const now = new Date();
    if (isBefore(addMinutes(lastCheck, 3), now)) {
      checkValidSession();
      lastCheck = now;
    }

    const intervalCall = setInterval(() => {
      checkValidSession();
      lastCheck = new Date();
    }, 180_000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [checkValidSession, focus]);
};
